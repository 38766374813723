import { useState } from "react";
import emailjs from "emailjs-com";
import React from "react";
import axios from "axios";

const initialState = {
  name: "",
  email: "",
  message: "",
};
export const Contact = (props) => {
  const [{ name, email, message }, setState] = useState(initialState);
  const date = new Date();
  const year = date.getFullYear()

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };
  const clearState = () => setState({ ...initialState });
  
  
  const handleSubmit = async (e) => {
    await axios.post('https://verticecc.cl/send-mail/', {
        name,
        email,
        message
    })
    .catch(function (error) {
        console.log(error);
      });

    clearState();
  };
  return (
    <div>
      <div id="contact">
        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className="section-title">
                <h2>Contáctanos</h2>
                <p>
                  Complete el siguiente formulario para enviarnos un correo electrónico y nos comunicaremos con usted a la brevedad.
                </p>
              </div>
              <form name="sentMessage" validate="true" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="name"
                        name="name"
                        className="form-control"
                        placeholder="Nombre"
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        className="form-control"
                        placeholder="Em@il"
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <textarea
                    name="message"
                    id="message"
                    className="form-control"
                    rows="4"
                    placeholder="Mensaje"
                    required
                    onChange={handleChange}
                  ></textarea>
                  <p className="help-block text-danger"></p>
                </div>
                <div id="success"></div>
                <button type="submit" className="btn btn-custom btn-lg">
                  Enviar
                </button>
              </form>
            </div>
          </div>
          <div className="col-md-3 col-md-offset-1 contact-info">
            <div className="contact-item">
              <h3>Información de Contacto</h3>
              <div className="contact-item">
                <p>
                  <span>
                    <i className="fa fa-phone"></i>Telefono: {props.data ? props.data.phone : "loading"}
                  </span>{" "}   
                </p>
              </div>
              <div className="contact-item">
                <iframe src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d19803.08528335153!2d-70.62185067188105!3d-33.39265744203199!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e3!4m0!4m5!1s0x9662c8ac660414a5%3A0xfa72d7f06fbb72be!2sDel%20Valle%20662%2C%20Huechuraba%2C%20Santiago%2C%20Regi%C3%B3n%20Metropolitana!3m2!1d-33.3935545!2d-70.61459219999999!5e0!3m2!1ses-419!2scl!4v1710958987569!5m2!1ses-419!2scl" style={{ width: 300, height: 300, border: 0 }} loading="lazy"></iframe>
              </div>
                <p>
                  <span>
                    <i className="fa fa-map-marker"></i> {props.data ? props.data.address : "loading"}
                  </span>
                </p>
            </div>
          </div>
        </div>
      </div>
      <div id="footer">
        <div className="container text-center">
          <p>
            &copy; {year} - Todos los derechos reservados.{" "}
          </p>
        </div>
      </div>
    </div>
  );
};
