import React from "react";
import { TbCashBanknoteOff } from "react-icons/tb";
import { TiGroup } from "react-icons/ti";
import { PiStrategyBold } from "react-icons/pi";

export const Services = (props) => {
  const MAP_ICONS = {
    "call-notification": <TbCashBanknoteOff size={80}/>,
    "call-aud": <TiGroup size={80}/>,
    "call-punish": <PiStrategyBold size={80}/>

  }
  return (
    <div id="services" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Nuestros Servicios</h2>
        </div>
        <div className="row">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-4">
                  {" "}
                  {MAP_ICONS[`${d.icon}`]}
                  <div className="service-desc">
                    <h3>{d.name}</h3>
                    <p>{d.text}</p>
                  </div>
                </div>
              ))
            : "loading"}
        </div>
      </div>
    </div>
  );
};
